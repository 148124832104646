import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="content-translator" 
export default class extends Controller {
  static targets = [
    "originText",
    "translatedText",
    "translateBtn",
    "backOriginBtn",
    "seeTranslationBtn"
  ]

  static values = {
    languagetarget: String
  }

  connect() {
    //console.log('connected from translator');
  } 

  fetch(text) {
    var url = "https://sacoddo.com:3030/api/v1"

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': 'sacoddo--40c782f9b0e07577cbbae61175d73279'
      },
      body: JSON.stringify({ 
        text: this.getText(text), 
        target: this.languagetargetValue
      })
    })
    .then(response => response.json())
    .then((data) => {
      this.translatedTextTarget.innerHTML = data.result
      this.toggleBtn()
      this.translateBtnTarget.classList.add("d-none")
      this.seeTranslationBtnTarget.classList.toggle("d-none")
    })
    .catch(error => console.error(error));
  }

  translate(){
    this.fetch(this.originTextTarget.innerHTML)
    //alert(this.originTextTarget.innerHTML)
  }

  getText(text){
    const regex = /#(\w+)/g;
    //const regex = /#([\u0600-\u06FF]+)/g;
    const hashtags = text.match(regex);
    if (hashtags && hashtags.length) {
      const hashtagstoreplace = text.match(regex).map(tag => tag.slice(1));
      const replacedText = text.replace(regex, (match, p1) => {
        const index = hashtagstoreplace.indexOf(p1);
        if (index !== -1) {
          return `<span class="notranslate">${match}</span>`;
        }
        return match;
      });
      return replacedText;
    }
    return text;
  }

  toggleBtn(){
    this.translatedTextTarget.classList.toggle("d-none")
    this.originTextTarget.classList.toggle("d-none")
    this.seeTranslationBtnTarget.classList.toggle("d-none")
    this.backOriginBtnTarget.classList.toggle("d-none")
  }
}
