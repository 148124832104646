import { Controller } from "@hotwired/stimulus"
var res = "test";
// Connects to data-controller="video"
export default class extends Controller {
  
  static targets = [ "preview"]
  static values = {
    xpg: String,
  }

   connect() {
    console.log("video controller")
    this.fetchVideos()

    console.log(res)
  }

  params() {
    let params = new URLSearchParams()
    params.append('gid', this.xpgValue)
    return params
  }

  async fetchVideos() {
    try {
      const response = await fetch(`/attachments?${this.params()}`, {
        headers: {  
          Accept: "application/json"  
        } 
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const userData = await response.json();
      this.displayUserData(userData)
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }

  displayUserData(userData) {
    let controller = this
    if (controller.hasPreviewTarget) {
      const video = document.createElement('video');
      const canvas = document.createElement('canvas');
      const img = document.createElement('img');

      video.src = userData.attachments[0].url;

      video.addEventListener('loadeddata', function() {
        // Set the time to capture the frame (e.g., 5 seconds)
        video.currentTime = 0.5;
      });

      video.addEventListener('seeked', function() {
        // Ensure the canvas size matches the video dimensions
        canvas.width = 100;
        canvas.height = 100;
  
        // Draw the video frame to the canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Convert the canvas to an image and display it
        img.src = canvas.toDataURL('image/jpeg');
  
        // Clean up
        //URL.revokeObjectURL(url);
        controller.previewTarget.appendChild(img);
      });

    }
    console.log(userData)
  }
}
