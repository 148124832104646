import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment"
export default class extends Controller {
  static values = {
    scrollto: String
  }

  connect() {
  }

  hideReplys(){
    this.element.remove()
    var comment = document.getElementById(this.scrolltoValue);
    console.log(comment)
    comment.scrollIntoView({behavior: 'smooth'}, true);
  }
}
