import { Controller } from "@hotwired/stimulus"
import flatpickr from 'flatpickr';
import rangePlugin from "flatpickr/dist/plugins/rangePlugin"
import { AlgerianArabic }  from "flatpickr/dist/l10n/ar-dz.js"
import { english }  from "flatpickr/dist/l10n/default.js"
import { French }  from "flatpickr/dist/l10n/fr.js"
import { Mandarin }  from "flatpickr/dist/l10n/zh.js"
import { Spanish }  from "flatpickr/dist/l10n/es.js"
import { Portuguese }  from "flatpickr/dist/l10n/pt.js"
import { German }  from "flatpickr/dist/l10n/de.js"
import { Japanese }  from "flatpickr/dist/l10n/ja.js"
import { Italian }  from "flatpickr/dist/l10n/it.js"
import { Turkish }  from "flatpickr/dist/l10n/tr.js"

// Connects to data-controller="search-listings"
export default class extends Controller {
  static targets = [ 
    "to", 
    "location", 
    "form",
    "checkinDate", 
    "checkoutDate", 
    "checkinDatePicker", 
    "checkoutDatePicker", 
    "mobileFlatpickr",
    "clearCalendarBtn"
  ]

  static values = {
    locale: String,
    inline: Boolean,
    mode: String,
    showmonths: Number
  }

  connect() {
    let controller = this
    if (controller.hasToTarget) {
      controller.toTarget.addEventListener('click', (e) => {
        controller.toTarget.parentElement.classList.toggle('opensugg');
      });
    }
    if (typeof (google) != "undefined") {
      this.initMap()
    }
    controller.initDatePicker()
  }

  initMap() {
    const options = {
      //
      types: ['(regions)'],
      //types: ['(cities)'],
      componentRestrictions: {
        country: ["dz"],
      }
    };

    const autocomplete = new google.maps.places.Autocomplete(this.toTarget, options);
    
    autocomplete.addListener("place_changed", () => {
      let fetchData = {
        headers: new Headers({
          'Content-Type': 'application/json; charset=UTF-8'
        })
      }
      const place = autocomplete.getPlace();
      this.toTarget.value = place.name
      console.log(place)
    })
  }

  initDatePicker(){
    let calendar
    let controller = this;
    let config = {
      "locale": controller.getLocale(this.localeValue),
      showMonths: controller.showmonthsValue,
      dateFormat: controller.getdateFormat(this.localeValue),
      mode: controller.modeValue,
      inline: controller.inlineValue,
      minDate: "today",
      disableMobile: true,
      nextArrow: "<i class='fi-chevrons-right fs-7'></i>",
      prevArrow: "<i class='fi-chevrons-left fs-7'></i>",
      "plugins": [
        new rangePlugin({ input: this.checkoutDatePickerTarget})
      ],
      onClose: function(selectedDates, dateStr, instance) {
        if (selectedDates.length > 0) {
          controller.checkinDateTarget.value = selectedDates[0].toString()
          controller.checkoutDateTarget.value = selectedDates[1].toString()
        }
      },
    }
    if (controller.hasMobileFlatpickrTarget) {
      config["appendTo"] = controller.mobileFlatpickrTarget
    }
    calendar = flatpickr(this.checkinDatePickerTarget, config);   
    
    controller.clearCalendarBtnTarget.addEventListener("click", (e) => {
      controller.clearCalendar(calendar)
    })
  }

  clearCalendar(calendar) {
    calendar.clear();
    this.checkinDateTarget.value = ""
    this.checkoutDateTarget.value = ""
  }

  clearPlace() {
    this.toTarget.value = ""
  }

  getLocale(locale) {
    // :ar, :en, :fr, :cn, :es, :pt, :de, :ja, :it, :tr
    if (locale == "ar") {
      return AlgerianArabic
    }

    if (locale == "en") {
      return english
    }

    if (locale == "fr") {
      return French
    }

    if (locale == "cn") {
      return Mandarin
    }

    if (locale == "es") {
      return Spanish
    }

    if (locale == "pt") {
      return Portuguese
    }

    if (locale == "de") {
      return German
    }

    if (locale == "ja") {
      return Japanese
    }

    if (locale == "it") {
      return Italian
    }

    if (locale == "tr") {
      return Turkish
    }
  }

  getdateFormat(locale) {
    if (locale == "ar") {
      return 'j F'
    }

    if (locale == "en") {
      return 'M j'
    }

    if (locale == "fr") {
      return 'j M. '
    }
    
    if (locale == "cn") {
      return 'M j'
    }

    if (locale == "es") {
      return 'M j'
    }

    if (locale == "pt") {
      return 'M j'
    }

    if (locale == "de") {
      return 'M j'
    }

    if (locale == "ja") {
      return 'M j'
    }

    if (locale == "it") {
      return 'M j'
    }

    if (locale == "tr") {
      return 'M j'
    }
  }

}
