import { Controller } from "@hotwired/stimulus"
import SlimSelect from "slim-select"

// Connects to data-controller="select2"
export default class extends Controller {

  static targets = [ "input", "bluring"]

  static values = {
    placeholder: String,
    searchText: String,
    searchPlaceholder: String,
  }
  connect() {
    let _this = this
    var displaySelect = new SlimSelect({
      select: this.inputTarget,
      searchText: this.searchTextValue,
      searchPlaceholder: this.searchPlaceholderValue,
    })
    
    var placeholder = this.element.querySelector('.placeholder');
    if (placeholder) {
      placeholder.classList.remove('placeholder');
    }

    var contentContainer = this.element.querySelector('.ss-content');
    if (this.hasBluringTarget) {
      
      var observer = new MutationObserver(function(mutationsList) {
        console.log(mutationsList)
        for (var mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (contentContainer.classList.contains('ss-open')) {
              _this.bluringTarget.style = "display: block !important;"
            } else {
              _this.bluringTarget.style = "display: none !important;"
            }
          }
        }
      });
      observer.observe(contentContainer, { attributes: true });
    }
    
    
  }  
}
