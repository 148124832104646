import { Controller } from "@hotwired/stimulus"

let slideMutedState = true

// Connects to data-controller="share-slider"
export default class extends Controller {
  static values = {
    mode: String
  }

  initialize() {
    this.slide = this.element
    
    this.active = 0
    this.init()
  }

  disconnect() {
    
  }

  init() {
    let controller = this
    this.next = this.next.bind(this)
    this.prev = this.prev.bind(this) 

    //create slide elements
    this.itemsToCreate = this.slide.querySelectorAll('.slide-items-create > *')
    this.slideHeight = this.slideHeight()
    
    this.createFirstElement()

    //start sliding
    this.items = this.slide.querySelectorAll('.slide-items > *')
    this.indicator = this.slide.querySelector('.slide-thumbs-indicator')
    this.addThumbIndicator()
    this.activeSlide(0)
    this.addNavigation()
  }

  addNavigation() {
    const nextBtn = this.slide.querySelector('.slide-next')
    const prevBtn = this.slide.querySelector('.slide-prev')

    nextBtn.addEventListener('click', this.next)
    prevBtn.addEventListener('click', this.prev)

    if (this.items.length > 1) {
      this.slide.querySelector('.slide-nav-btns').classList.remove('d-none')
    }
  }

  addThumbIndicator() {
    if (this.items.length > 1) {
      this.indicator.classList.remove('d-none')
    }
    this.indicator.innerHTML = `<span class="indicator">${this.active + 1}/${this.items.length}</span>`
  }

  createFirstElement() {
    const container = this.slide.querySelector('.slide-items');
    this.itemsToCreate.forEach((item, index) => {
      if (item.classList.contains("gallery-video")) {
        container.appendChild(this.createVideoElement(item, index));
      }
      if (item.classList.contains("gallery-image")) {
        container.appendChild(this.createImageElement(item, index));
      }
    })
  }

  createVideoElement(item, index) {
    let controller = this
    const videoMetaData = JSON.parse(item.getAttribute("data-video-metadata"))

    const galleryItem = document.createElement('div');
    galleryItem.classList.add('bg-black', 'text-center', 'w-100')
    galleryItem.classList.add('gallery-video')
    //galleryItem.style = `max-height: inherit !important; min-height: inherit !important; aspect-ratio: 9/16 !important; height: ${this.slideHeight}px !important;`
    if (controller.hasModeValue && controller.modeValue === "article") {
      galleryItem.style = `max-height: inherit !important; min-height: inherit !important; height: ${this.slideHeight}px !important;`
    } else {
      galleryItem.style = `max-height: inherit !important; min-height: inherit !important;`
    }

    
    const vidContainer = document.createElement('div');
    vidContainer.classList.add('h-100', 'position-relative', 'd-flex', 'align-items-center', 'justify-content-center')

    const video = document.createElement('video');
    video.src = item.getAttribute("data-video-src");
    video.setAttribute("data-video-metadata", item.getAttribute("data-video-metadata"));
    video.controls = false;
    video.autoplay = false;
    video.loop = false;
    video.preload = 'auto'
    video.volume = 0
    video.pause();
    video.classList.add('video')
    video.classList.add('h-100', 'w-100')

    if (videoMetaData.height >= videoMetaData.width) {
      if (videoMetaData.height > videoMetaData.width) {
        video.style = `aspect-ratio: 9/16 !important;`
      } else {
        video.style = `aspect-ratio: 1/1 !important;`
      }
    } else {
      video.style = `aspect-ratio: 16/9 !important;`
    }

    video.addEventListener('ended', (e) => {
      let btn = e.target.parentNode.parentNode.querySelector('.video-control-pause')
      btn.classList.remove('bi-pause-fill')
      btn.classList.remove('bi-play-fill')
      btn.classList.add('bi-arrow-clockwise')
    })

    video.addEventListener('play', (e) => {
      let btn = e.target.parentNode.parentNode.querySelector('.video-control-pause')
      btn.classList.remove('bi-play-fill')
      btn.classList.remove('bi-arrow-clockwise')
      btn.classList.add('bi-pause-fill')
    })

    video.addEventListener('pause', (e) => {
      let btn = e.target.parentNode.parentNode.querySelector('.video-control-pause')
      btn.classList.remove('bi-pause-fill')
      btn.classList.remove('bi-arrow-clockwise')
      btn.classList.add('bi-play-fill')
    })

    //btns
    const pauseBtn = document.createElement('i');    
    pauseBtn.classList.add('video-control-pause')
    pauseBtn.classList.add('border-0', 'btn', 'btn-xs', 'btn-icon', 'bi', 'bi-play-fill', 'bg-blur', 'text-light', 'rounded-3', 'slide-btn-icon')
    pauseBtn.addEventListener('click', (e) => {
      let video = e.target.parentNode.parentNode.querySelector('.video')
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    })

    const muteBtn = document.createElement('i');    
    muteBtn.classList.add('video-control-mute')    
    muteBtn.classList.add('border-0', 'btn', 'btn-xs', 'btn-icon', 'bi', 'bi-volume-up-fill', 'bg-blur', 'text-light', 'rounded-3', 'slide-btn-icon')
    muteBtn.addEventListener('click', (e) => {
      let video = e.target.parentNode.parentNode.querySelector('.video')
      // console.log()
      if (video.muted) {
        video.volume = 1
        slideMutedState = false
        video.muted = slideMutedState
        e.target.classList.add('bi-volume-up-fill')
        e.target.classList.remove('bi-volume-mute-fill')
      } else {
        video.volume = 0
        slideMutedState = true
        video.muted = slideMutedState
        e.target.classList.remove('bi-volume-up-fill')
        e.target.classList.add('bi-volume-mute-fill')
      }      
    })
    
    const controls = document.createElement('div');
    controls.classList.add('p-2', 'position-absolute', 'bottom-0', 'start-0', 'zindex-1', 'd-flex', 'gap-2')

    controls.appendChild(pauseBtn);
    controls.appendChild(muteBtn);

    vidContainer.appendChild(video);
    galleryItem.appendChild(vidContainer);
    galleryItem.appendChild(controls);

    ClickAndHold.apply(galleryItem, 500, 
      () => {
        controller.slide.querySelector('.slide-nav-btns').classList.add('slide-hold')
        controller.slide.querySelector('.slide-thumbs-indicator').classList.add('slide-hold')
      }, 
      () => {
        controller.slide.querySelector('.slide-nav-btns').classList.remove('slide-hold')
        controller.slide.querySelector('.slide-thumbs-indicator').classList.remove('slide-hold')
      }
    );

    let touchstartX = 0
    let touchendX = 0
    let downEvents = ['touchstart']
    let upEvents = ['touchend']

    downEvents.forEach(eventType => {
      video.addEventListener(eventType, e => {
        touchstartX = e.changedTouches[0].screenX
      }, false)
    });

    upEvents.forEach(eventType => {
      video.addEventListener(eventType, e => {
        touchendX = e.changedTouches[0].screenX
        controller.checkDirection(touchstartX, touchendX)
      }, false)
    });

    // document.addEventListener('scroll', function () {
    //   if (controller.isInViewport(video) && controller.active === index) {        
    //     video.volume = 1
    //     video.play();
    //   } else {
    //     video.volume = 0
    //     video.pause();
    //   }
    // });

    var observer = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting  && controller.active === index) {
          // Play the video when it comes into the viewport
          video.volume = 1
          video.play();
        } else {
          // Pause the video when it goes out of the viewport
          video.volume = 0
          video.pause();
        }
      });
    }, { threshold: 1 });
    observer.observe(video);
    
    return galleryItem
  }
  
  createImageElement(item, index) {
    let controller = this
    const imgMetaData = JSON.parse(item.getAttribute("data-img-metadata")) 

    const galleryItem = document.createElement('div');
    galleryItem.classList.add('bg-black', 'text-center', 'h-100')
    galleryItem.classList.add('gallery-image')
    if (controller.hasModeValue && controller.modeValue === "article") {
      galleryItem.style = `max-height: inherit !important; min-height: inherit !important; height: ${this.slideHeight}px !important;`
    } else {
      galleryItem.style = `max-height: inherit !important; min-height: inherit !important;`
    }

    const imgContainer = document.createElement('div');
    imgContainer.classList.add('h-100', 'position-relative', 'd-flex', 'align-items-center', 'justify-content-center')

    const image = document.createElement('img');
    image.src = item.getAttribute("data-img-src");
    image.classList.add('image', 'object-fit-cover')

    var viewportWidth = this.slide.clientWidth;
    var viewportHeight = this.slide.clientHeight;
    //var imageWidth = image.width;
    //var imageHeight = image.height;
    var widthRatio = viewportWidth / imgMetaData.width;
    var heightRatio = viewportHeight / imgMetaData.height;
    
    if (imgMetaData.height >= imgMetaData.width) {
      if (imgMetaData.height > imgMetaData.width) {
        if (this.hasModeValue && this.modeValue === "article") {
          image.classList.add('h-100')
          image.style = `aspect-ratio: 4/5 !important;`          
        } else {
          image.style = `max-width: 100%; max-height: 100%;`
        }
      } else {
        if (this.hasModeValue && this.modeValue === "article") {
          image.classList.add('w-100')
          image.style = `aspect-ratio: 1/1 !important;`        
        } else {
          image.style = `max-width: 100%; max-height: 100%;`
        }
      }
    } else {
      if (this.hasModeValue && this.modeValue === "article") {
        image.classList.add('w-100')
        image.style = `aspect-ratio: 1.91:1 !important;`  
      } else {
        image.style = `max-width: 100%; max-height: 100%;`
      }
    }

    image.addEventListener('contextmenu', (e) => {
      e.preventDefault()
    });

    
    imgContainer.appendChild(image);
    galleryItem.appendChild(imgContainer);
    
    ClickAndHold.apply(galleryItem, 500, 
      () => {
        controller.slide.querySelector('.slide-nav-btns').classList.add('slide-hold')
        controller.slide.querySelector('.slide-thumbs-indicator').classList.add('slide-hold')
      }, 
      () => {
        controller.slide.querySelector('.slide-nav-btns').classList.remove('slide-hold')
        controller.slide.querySelector('.slide-thumbs-indicator').classList.remove('slide-hold')
      }
    );

    let touchstartX = 0
    let touchendX = 0
    let downEvents = ['touchstart']
    let upEvents = ['touchend']

    downEvents.forEach(eventType => {
      galleryItem.addEventListener(eventType, e => {
        touchstartX = e.changedTouches[0].screenX
      }, false)
    });

    upEvents.forEach(eventType => {
      galleryItem.addEventListener(eventType, e => {
        touchendX = e.changedTouches[0].screenX
        controller.checkDirection(touchstartX, touchendX)
      }, false)
    });
    
    return galleryItem
  }

  /** @param {Number} index */
  activeSlide(index) {
    this.active = index
    this.items.forEach((item) => item.classList.remove('active'))
    this.items[index].classList.add('active')
    this.indicator.querySelector('.indicator').innerHTML = `${this.active + 1}/${this.items.length}`

    if (this.items[this.active].classList.contains("gallery-video") && index !== 0) {
      let video = this.items[this.active].querySelector(".video")
      let pauseBtn = this.items[this.active].querySelector(".video-control-pause")
      video.volume = 1
      video.muted = false
      video.play()
      pauseBtn.classList.add('bi-pause-fill')
      pauseBtn.classList.remove('bi-play-fill')
    }
  }

  next() { 
    if (this.items[this.active].classList.contains("gallery-video")) {
      let video = this.items[this.active].querySelector(".video")
      let pauseBtn = this.items[this.active].querySelector(".video-control-pause")
      video.volume = 0
      video.muted = true
      video.pause()
      pauseBtn.classList.add('bi-play-fill')
      pauseBtn.classList.remove('bi-pause-fill')
    }
    if (this.active < this.items.length - 1) {
      this.activeSlide(this.active + 1)
    } else {
      this.activeSlide(0)
    }
  }

  prev() {
    if (this.items[this.active].classList.contains("gallery-video")) {
      let video = this.items[this.active].querySelector(".video")
      let pauseBtn = this.items[this.active].querySelector(".video-control-pause")
      video.volume = 0
      video.muted = true
      video.pause()
      pauseBtn.classList.add('bi-play-fill')
      pauseBtn.classList.remove('bi-pause-fill')
    }
    if (this.active > 0) {
      this.activeSlide(this.active - 1)
    } else {
      this.activeSlide(this.items.length - 1)
    }
  }

  slideHeight() {
    let metaData = 0
    let height = 0
    this.itemsToCreate.forEach((item, index) => {
      if (item.classList.contains("gallery-video")) {
        metaData = JSON.parse(item.getAttribute("data-video-metadata"))
      }
      if (item.classList.contains("gallery-image")) {
        metaData = JSON.parse(item.getAttribute("data-img-metadata"))
      }
      if (metaData.height > height) {
        height = metaData.height * (this.slide.clientWidth / metaData.width)
      }
    })

    return height
  }

  isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      //rect.top <= ( (window.innerHeight) - (el.clientHeight*.3) ) && rect.top >= ( 0 - ( el.clientHeight*.3 ) ) &&
      rect.top <= ( (window.innerHeight) - (el.clientHeight*1) ) && rect.top >= ( 0 - ( el.clientHeight*.1) )
    );
  }
  
  checkDirection(touchstartX, touchendX) {
    if (touchendX - touchstartX < -50) this.next()
    if (touchendX - touchstartX > 50) this.prev()
  }

  
}

class ClickAndHold {
  constructor(target, timeout, StartCallback, EndCallback) {
    this.target = target;
    this.timeout = timeout;
    this.StartCallback = StartCallback;
    this.EndCallback = EndCallback;
    this.isHeld = false;
    this.activeHoldTimeoutId = null;

    ["mousedown", "touchstart"].forEach(eventType => {
      this.target.addEventListener(eventType, this._onTouchStart.bind(this), false)
    });

    ["mouseup",  "touchend" ].forEach(eventType => {
      this.target.addEventListener(eventType, this._onTouchEnd.bind(this), false)
    });
  }

  _onTouchStart(e) {
    clearTimeout(this.activeHoldTimeoutId);
    this.activeHoldTimeoutId = setTimeout(() => {
      console.log('hold')
      this.isHeld = true;
      if (this.isHeld) {
        this.StartCallback();
      }
    }, this.timeout);
  }

  _onTouchEnd(e) {
    clearTimeout(this.activeHoldTimeoutId);
    if (this.isHeld) {
      this.isHeld = false;
      this.EndCallback();
    }
  }
  
  static apply(target, timeout, StartCallback, EndCallback) {
    new ClickAndHold(target, timeout, StartCallback, EndCallback);
  }
}