import { Controller } from "@hotwired/stimulus"
import Parallax from 'parallax-js'
import { jarallax, jarallaxVideo } from "jarallax";
import Rellax from "rellax";

// Connects to data-controller="parallax"
export default class extends Controller {
  connect() {
    var element = document.querySelectorAll('.parallax');
    for (var i = 0; i < element.length; i++) {
        var parallaxInstance = new Parallax(element[i]);
    }

    var rellax = new Rellax('.rellax');
    jarallaxVideo();
    jarallax(document.querySelectorAll(".jarallax"));
  }
}
